import {
  Section,
  TextHeading1,
  TextHeading2,
  TextParagraph,
} from '@ferreri/prever-component-lib';

export const SummarySection = () => (
  <Section>
    <TextHeading1>
      O que é o <span translate="no">Angeplus?</span>
    </TextHeading1>
    <TextHeading2>Mais vantagens para você!</TextHeading2>
    <TextParagraph>
      No Angeplus você resolve tudo de forma simples e rápida! <br />
      Gerencie seu plano, consulte o guia médico, faça agendamentos online e
      aproveite os benefícios do Clube de Vantagens! <br /> Seja um associado e
      desfrute desses e muitos outros benefícios.
    </TextParagraph>
  </Section>
);
