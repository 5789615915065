import {
  Content,
  DividerHorizontal,
  SectionInstallPWA,
} from '@ferreri/prever-component-lib';

import { Banner } from './Banner';
import { HubHero } from './HubHero';
import { PlanBenefitsSection } from './PlanBenefitsSection';
import { ServicesWebSection } from './ServicesWebSection';
import { SummarySection } from './SummarySection';

export const Hub = () => {
  return (
    <>
      <SectionInstallPWA />
      <HubHero />
      <Content>
        <SummarySection />
        <DividerHorizontal />
        <PlanBenefitsSection />
        <DividerHorizontal />
        <ServicesWebSection />
      </Content>
      <Banner />
    </>
  );
};
