import { Section, TextHeading1 } from '@ferreri/prever-component-lib';
import { PlanBenefitsList } from './PlanBenefitsList';

export const PlanBenefitsSection = () => (
  <Section>
    <TextHeading1>
      Planos <span translate="no">Prever Angelus</span>
    </TextHeading1>
    <PlanBenefitsList />
  </Section>
);
