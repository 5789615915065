import {
  CardButtonMedium,
  GroupElementGrid,
  LinkWrapper,
  PreverAngelusFillXLargeIcon,
  ProductClubeFillXLargeIcon,
  ProductHeathFillXLargeIcon,
  ProductPosthumousFillXLargeIcon,
  token,
} from '@ferreri/prever-component-lib';

import * as routes from '../routes';

export const ServicesWebCardButtonList = () => {
  return (
    <GroupElementGrid>
      {Object.values(SERVICES).map(({ Icon, description, title, uri }) => (
        <LinkWrapper href={uri} key={`${title}-${uri}`}>
          <CardButtonMedium
            icon={Icon}
            title={title}
            description={description}
          />
        </LinkWrapper>
      ))}
    </GroupElementGrid>
  );
};

const SERVICES = {
  plans: {
    Icon: <PreverAngelusFillXLargeIcon color={token.colors.neutralXxDark} />,
    title: 'Plano',
    description: 'Associe-se de forma rápida e descomplicada!',
    uri: routes.PLAN,
  },
  clube: {
    Icon: (
      <ProductClubeFillXLargeIcon color={token.colors.solidClubePrimaryPure} />
    ),
    title: 'Clube de Vantagens',
    description:
      'Um clube com descontos exclusivos em produtos e serviços de grandes marcas e parceiros locais.',
    uri: routes.CLUBE,
  },
  health: {
    Icon: (
      <ProductHeathFillXLargeIcon color={token.colors.solidSaudePrimaryPure} />
    ),
    title: 'Cuidados e Saúde',
    description:
      'Praticidade e descontos especiais na área da saúde para o associado.',
    uri: routes.HEALTH,
  },
  posthumous: {
    Icon: (
      <ProductPosthumousFillXLargeIcon
        color={token.colors.solidPostumosPrimaryPure}
      />
    ),
    title: 'Serviços Funerários',
    description:
      'Excelência e humanização no atendimento funeral com cobertura completa.',
    uri: routes.POSTHUMOUS,
  },
};
