import styled from '@emotion/styled';
import {
  AngeplusLightLogo,
  ButtonNormalPrimaryMedium,
  Hero,
  LinkWrapper,
  TextDisplayAlt,
  token,
} from '@ferreri/prever-component-lib';
import { useRouter } from 'next/router';

import { AUTH_IDENTIFICATION } from '../routes';

export const HubHero = () => {
  const router = useRouter();
  const source = {
    uri: `${router.basePath}/assets/img/medium-shot-smiley-family-with-phone.webp`,
  };

  return (
    <Hero
      source={source}
      header={<AngeplusLightLogo height={48} width={120} />}
    >
      <SectionHero>
        <TextDisplayAlt>Várias soluções em um só lugar!</TextDisplayAlt>
        <LinkWrapper href={AUTH_IDENTIFICATION}>
          <ButtonNormalPrimaryMedium>
            Entrar no Angeplus
          </ButtonNormalPrimaryMedium>
        </LinkWrapper>
      </SectionHero>
    </Hero>
  );
};

const SectionHero = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${token.spacing.spacingSmall};
`;
